.App {
  text-align: center;
  /* margin: 0px 0px 10px 0px;
  padding: 15px; */
  background: rgb(243, 244, 249);
  display: inline-flex;
  width: 100%;
  height: inherit;
}

.AppLogo {
  height: 40px;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .AppLogo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.AppHeaderPart1 {
  height: 40px;
  width: 19%;
  background: #fff;
  border-radius: 35px 0px 0px 35px;
}

.AppHeaderPart2 {
  width: 79%;
  height: 40px;
  background: #fff;
  display: inline-flex;
  border-radius: 0px 35px 35px 0px;
}

.AppHeader {
  margin-top: 10px;
  background-color: #ffffff;
  /* min-height: 100vh; */
  display: inline-flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* padding: 10px; */
  border-radius: 30px;
  height: 60px;
  width: 100%;
  box-shadow: rgba(95, 116, 141, 0.03) 0px 2px 1px -1px, rgba(95, 116, 141, 0.04) 0px 1px 1px 0px,
    rgba(95, 116, 141, 0.08) 0px 1px 3px 0px;
  /* border: 2px solid #f0f7fc; */
  margin-bottom: 10px;
}

.App-link {
  color: #61dafb;
}

.SwipeableViews {
  width: 100%;
  border-radius: 10px;
  margin: auto;
  margin-left: 6px;
  /* background: #fff; */
  /* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3); */
}

.AppHeaderMobile {
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 2;
  width: 100%;
  position: fixed;
  margin: auto;
  bottom: -40px;
  background: #25272e;
  padding: 10px;
  height: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

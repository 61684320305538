.markerGroup {
  height: 50px;
  background: #ffffff;
  margin: 3px;
  margin-bottom: 15px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  display: grid;
  text-align: center;
  align-content: center;
  width: 100%;
  grid-column-gap: 0;
  grid-row-gap: 0;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(4, 1fr);
}

#LightNormalIcon {
  background-size: cover;
  width: 70px;
  height: 70px;
  background-image: url('img/street_light_1.png');
}

#LightErrorIcon {
  background-size: cover;
  width: 70px;
  height: 70px;
  background-image: url('img/street_light_2.png');
}

#LightPostNormalIcon {
  background-size: cover;
  width: 70px;
  height: 70px;
  background-image: url('img/street_lights_1.png');
}

.css_1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #23366bde;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  text-size-adjust: 100%;
}

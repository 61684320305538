.Wrapper {
  width: 20%;
  /* width: 212px; */
  background: white;
  padding: 15px;
  /* margin: 10px; */
  margin-right: 15px;
  margin-top: -15px;
  margin-left: -15px;
  min-height: 100vh;
}

.AppLogo {
  height: 40px;
  pointer-events: none;
}

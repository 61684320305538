.MainPageBody {
  background: linear-gradient(130deg, rgb(243, 244, 249) 0%, rgba(130, 170, 231, 0.46) 100%);
  height: 100vh;
  display: flex;
}

.LoginWindow {
  height: 50%;
  width: 50%;
  background: linear-gradient(130deg, rgb(232 237 248) 0%, rgb(106 123 227 / 10%) 100%);
  display: flex;
  margin: auto;
  border-radius: 10px;
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

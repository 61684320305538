.img_css {
  width: 40px;
  height: 40px;
  background: #2499ef;
  padding: 8px;
  border-radius: 50%;
  margin: 0 7px 0 7px;
}

.css_1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.css_1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 115px);
}

.css_2 {
  display: flex;
  flex-direction: row;
  margin: 20px;
}
